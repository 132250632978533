<template>
  <Auth />
  <div class="py-4 container-fluid">
    <div class="mt-4 row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header">
            <div class="row">
              <div class="col-md-6">
                <h5 class="mb-0">Kirim Paket</h5>
                <p class="mb-0 text-sm">
                  Silahkan isi form dibawah ini dengan lengkap untuk melakukan
                  proses kirim paket.
                </p>
              </div>
              <div v-show="false" class="col-md-6 text-end">
                <button
                  class="btn btn-primary"
                  @click.prevent="handleRedirectImportCSV"
                >
                  <i class="fa fa-file-csv"></i>&nbsp;&nbsp;Import Paket (CSV)
                </button>
              </div>
            </div>
          </div>
          <div class="card-body">
            <form @submit.prevent="onSubmit">
              <div class="row">
                <div class="col-md-6">
                  <div class="card">
                    <div class="card-body">
                      <input
                        v-model="form.official_dropship_store_id"
                        type="hidden"
                      />
                      <input
                        v-model="form.origin_address_list_id"
                        type="hidden"
                      />
                      <input
                        v-model="form.origin_ro_subdistrict_id"
                        type="hidden"
                      />
                      <h6 class="text-secondary">#DETAIL PENGIRIM</h6>
                      <el-skeleton
                        v-if="skeletonLoadingOriginDetail"
                        :rows="3"
                        animated
                      />
                      <div v-if="showOriginDetail">
                        <p>
                          <b>{{ origin.labelName }}</b
                          >&nbsp;
                          <argon-badge
                            size="sm"
                            variant="gradient"
                            color="success"
                          >
                            {{ origin.labelAddressType }}
                          </argon-badge>
                        </p>
                        <p class="text-sm">
                          {{ origin.labelAddress }}<br />{{ origin.labelArea }}
                        </p>
                        <div class="text-end">
                          <el-tooltip
                            class="box-item"
                            effect="dark"
                            content="Edit pengirim"
                            placement="top-start"
                          >
                            <a href="#" @click.prevent="modals.classic = true">
                              <i class="fas fa-edit text-primary"></i>
                            </a>
                          </el-tooltip>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card mt-2">
                    <div class="card-body">
                      <input
                        v-model="form.destination_address_list_id"
                        type="hidden"
                      />
                      <input
                        v-model="form.destination_ro_subdistrict_id"
                        type="hidden"
                      />
                      <div class="row">
                        <div class="col-md-6">
                          <h6 class="text-secondary">#DETAIL PENERIMA</h6>
                        </div>
                        <div class="col-md-6 text-end text-sm">
                          <a
                            href="#"
                            class="text-primary"
                            @click.prevent="handleShowAddressListsNoPickup"
                          >
                            Cari di daftar alamat&nbsp;
                            <i class="fas fa-share text-primary"></i>
                          </a>
                        </div>
                      </div>
                      <div v-if="showFormAddressDestination">
                        <div class="row">
                          <div class="col-md-6">
                            <label class="form-label">Nama Penerima</label>
                            <input
                              v-model="form.destination_name"
                              type="text"
                              class="form-control form-control-lg"
                              maxlength="50"
                              :disabled="disabledFormDestination == 1"
                            />
                          </div>
                          <div class="col-md-6">
                            <label class="form-label">Nomor Telepon</label>
                            <input
                              v-model="form.destination_phone"
                              type="text"
                              class="form-control form-control-lg"
                              maxlength="50"
                              :disabled="disabledFormDestination == 1"
                            />
                          </div>
                        </div>
                        <div class="row mt-2">
                          <div class="col-md-12">
                            <label class="form-label">Kecamatan</label>
                            <AutocompleteZonecode
                              v-if="showAutocompleteDestination"
                              id="destination"
                              name="destination"
                              name-i-d="destination_id"
                              clasname="form-control"
                              :urlsearch="urlsearch"
                              :value-i-d="form.destination_ro_subdistrict_id"
                              :value-area="form.destination"
                              get_district_id="yes"
                              get_postalcode="yes"
                              :disabled-form="disabledFormDestination"
                              @autocompletecallback="handleAutocompleteCallback"
                              @autocompleteloadingcallback="
                                handleAutocompleteLoadingCallback
                              "
                            />
                          </div>
                        </div>
                        <div class="row mt-2">
                          <div class="col-md-12">
                            <label class="form-label">Kelurahan</label>
                            <select
                              v-model="form.destination_subdistrict_id"
                              class="form-select form-select-lg"
                              :disabled="disabledFormDestination == 1"
                            >
                              <option
                                v-for="select in selects.subdistricts"
                                :key="select.value"
                                :value="select.value"
                              >
                                {{ select.name }}
                              </option>
                            </select>
                          </div>
                        </div>
                        <div class="row mt-2">
                          <div class="col-md-12">
                            <label class="form-label">Alamat Penerima</label>
                            <textarea
                              v-model="form.destination_address"
                              type="text"
                              class="form-control"
                              rows="3"
                              maxlength="255"
                              :disabled="disabledFormDestination == 1"
                            ></textarea>
                          </div>
                        </div>
                        <div class="row mt-2">
                          <div class="col-md-6">
                            <label class="form-label">Kodepos</label>
                            <input
                              v-model="form.destination_postalcode"
                              type="text"
                              class="form-control form-control-lg"
                              maxlength="7"
                              :disabled="disabledFormDestination == 1"
                            />
                          </div>
                          <div class="col-md-6">
                            <label class="form-label">Rumah/Kantor?</label>
                            <select
                              v-model="form.destination_address_type"
                              class="form-select form-select-lg"
                              :disabled="disabledFormDestination == 1"
                            >
                              <option
                                v-for="select in selects.address_types"
                                :key="select.value"
                                :value="select.value"
                              >
                                {{ select.name }}
                              </option>
                            </select>
                          </div>
                        </div>
                        <div class="row mt-2">
                          <div class="col-md-12">
                            <argon-checkbox
                              v-if="showEditAddressForm"
                              id="checkboxAddressList"
                              name="opsiAddressList"
                              :checked="form.checkedAddToAddressList"
                              @click="handleAddToAddressList"
                              >Perbaharui alamat ini</argon-checkbox
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card mt-2">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-md-6">
                          <h6 class="text-secondary">#DETAIL BARANG</h6>
                        </div>
                        <div class="col-md-6 text-end text-sm">
                          <a
                            href="#"
                            class="text-primary"
                            @click.prevent="handleShowProductCatalog"
                          >
                            Cari di katalog produk&nbsp;
                            <i class="fas fa-tags text-primary"></i>
                          </a>
                        </div>
                      </div>
                      <div v-if="tableCartProduct">
                        {{}}
                        <div
                          v-if="form.cartProductItems.length == 0"
                          class="text-center p-4"
                        >
                          <span class="text-secondary"
                            ><i>Belum ada produk yang dipilih.</i></span
                          >
                        </div>
                        <div
                          v-for="(item, index) in form.cartProductItems"
                          :key="item"
                          class="text-sm cart-row-product"
                        >
                          <div class="row mt-2">
                            <div class="col-md-3">
                              <div v-if="item.html_main_product_image != ''">
                                <span
                                  v-html="item.html_main_product_image"
                                ></span>
                              </div>
                              <div v-else>no img</div>
                            </div>
                            <div class="col-md-9">
                              <b>Nama Produk:</b> {{ item.product_name }}<br />
                              <b>Harga:</b> {{ item.display_order_price }}<br />
                              <b>Berat/Dimensi:</b> {{ item.weight }} gram /
                              P.{{ item.length }} cm L.{{ item.width }} cm T.{{
                                item.height
                              }}
                              cm<br />
                              <div class="row">
                                <div class="col-md-10">
                                  <div class="input-number-updown">
                                    <ul>
                                      <li
                                        class="numberdown"
                                        @click.prevent="
                                          handleUpdateQtyCartProduct(
                                            'down',
                                            index,
                                            item.qty
                                          )
                                        "
                                      >
                                        -
                                      </li>
                                      <li class="numbervalue">
                                        {{ item.qty }}
                                      </li>
                                      <li
                                        class="numberdown"
                                        @click.prevent="
                                          handleUpdateQtyCartProduct(
                                            'up',
                                            index,
                                            item.qty
                                          )
                                        "
                                      >
                                        +
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                                <div class="col-md-2 pt-2">
                                  <el-tooltip
                                    class="box-item"
                                    effect="dark"
                                    content="Hapus"
                                    placement="top-start"
                                  >
                                    <a
                                      href="#"
                                      @click.prevent="
                                        handleDeleteCartProduct(index)
                                      "
                                    >
                                      <i class="fas fa-times text-danger"></i>
                                    </a>
                                  </el-tooltip>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div v-if="formAddProduct">
                        <div class="row">
                          <div class="col-md-6">
                            <label class="form-label">Nama Produk</label>
                            <input
                              v-model="form.product_catalog_id"
                              type="hidden"
                            />
                            <input
                              v-model="form.product_name"
                              type="text"
                              class="form-control form-control-lg"
                            />
                          </div>
                          <div class="col-md-6">
                            <label class="form-label">Kategori Barang</label>
                            <select
                              v-model="form.product_category_id"
                              class="form-select form-select-lg"
                              required
                            >
                              <option
                                v-for="select in selects.product_categories"
                                :key="select.value"
                                :value="select.value"
                              >
                                {{ select.name }}
                              </option>
                            </select>
                          </div>
                        </div>
                        <div class="row mt-2">
                          <div class="col-md-6">
                            <label class="form-label"
                              >Nilai Barang
                              <small class="text-secondary"
                                >(rupiah)</small
                              ></label
                            >
                            <input
                              v-model="form.product_price"
                              type="text"
                              class="form-control form-control-lg"
                              @input="handleClearResultTariff"
                            />
                          </div>
                          <div class="col-md-6">
                            <label class="form-label"
                              >Jumlah Item dalam Paket
                              <small class="text-secondary">(qty)</small></label
                            >
                            <input
                              v-model="form.product_qty"
                              type="number"
                              class="form-control form-control-lg"
                              style="max-width: 150px"
                              @input="handleClearResultTariff"
                            />
                          </div>
                        </div>
                        <div class="row mt-2">
                          <div class="col-md-3">
                            <label class="form-label"
                              >Berat
                              <small class="text-secondary"
                                >(gram)</small
                              ></label
                            >
                            <input
                              v-model="form.product_weight"
                              type="number"
                              class="form-control form-control-lg"
                              @input="handleClearResultTariff"
                            />
                          </div>
                          <div class="col-md-3">
                            <label class="form-label"
                              >Panjang
                              <small class="text-secondary">(cm)</small></label
                            >
                            <input
                              v-model="form.product_length"
                              type="number"
                              class="form-control form-control-lg"
                              @input="handleClearResultTariff"
                            />
                          </div>
                          <div class="col-md-3">
                            <label class="form-label"
                              >Lebar
                              <small class="text-secondary">(cm)</small></label
                            >
                            <input
                              v-model="form.product_width"
                              type="number"
                              class="form-control form-control-lg"
                              @input="handleClearResultTariff"
                            />
                          </div>
                          <div class="col-md-3">
                            <label class="form-label"
                              >Tinggi
                              <small class="text-secondary">(cm)</small></label
                            >
                            <input
                              v-model="form.product_height"
                              type="number"
                              class="form-control form-control-lg"
                              @input="handleClearResultTariff"
                            />
                          </div>
                        </div>
                        <div class="row mt-2">
                          <div class="col-md-12">
                            <label class="form-label">Catatan</label>
                            <textarea
                              v-model="form.product_description"
                              type="text"
                              class="form-control"
                              rows="3"
                              placeholder="Barang pecah belah"
                            ></textarea>
                          </div>
                        </div>
                        <div class="row mt-2">
                          <div class="col-md-12">
                            <argon-checkbox
                              v-if="showCheckedAddToProductCatalog"
                              id="checkboxProductCatalog"
                              name="opsiProductCatalog"
                              @click="handleAddToProductCatalog"
                              >Tambahkan ke katalog produk</argon-checkbox
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="card">
                    <div class="card-body">
                      <h6 class="text-secondary">#PENGIRIMAN & PEMBAYARAN</h6>
                      <div class="row">
                        <div class="col-md-6">
                          <label class="form-label">Opsi Penjemputan</label>
                          <select
                            v-model="form.is_pickup_required"
                            class="form-select form-select-lg"
                            @change="handlePickupRequired"
                          >
                            <option
                              v-for="select in selects.is_pickup_required"
                              :key="select.value"
                              :value="select.value"
                            >
                              {{ select.name }}
                            </option>
                          </select>
                        </div>
                        <div class="col-md-6">
                          <label class="form-label">Metode Pembayaran</label>
                          <select
                            v-model="form.is_cod"
                            class="form-select form-select-lg"
                            @change="handleSelectCOD"
                          >
                            <option
                              v-for="select in selects.is_cod"
                              :key="select.value"
                              :value="select.value"
                            >
                              {{ select.name }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="row mt-2">
                        <div class="col-md-12">
                          <argon-alert
                            v-if="showAlertChoosePickup"
                            color="success"
                          >
                            <small
                              ><strong>PICK UP</strong>: Paket ini akan
                              <strong><u>DIAMBIL</u></strong> oleh agen
                              kurir.</small
                            >
                          </argon-alert>
                          <argon-alert
                            v-if="showAlertChooseDropoff"
                            color="primary"
                          >
                            <small
                              ><strong>DROP OFF</strong>: Paket ini harus
                              <strong><u>DIANTARKAN</u></strong> ke agen
                              kurir.</small
                            >
                          </argon-alert>
                        </div>
                      </div>
                      <div class="row mt-2">
                        <div class="col-md-12">
                          <argon-checkbox
                            id="checkboxIsInsuranceRequired"
                            name="opsiIsInsuranceRequired"
                            @click="handleIsInsuranceRequired"
                            >Asuransikan Kiriman (biaya
                            tambahan)</argon-checkbox
                          >
                        </div>
                      </div>
                      <div v-if="showInputCustomCOD" class="row mt-2">
                        <div class="col-md-12">
                          <label class="form-label">Custom Nilai COD</label>
                          <input
                            v-model="form.input_custom_cod"
                            type="text"
                            class="form-control form-control-lg"
                            @input="handleChangeInputCustomCOD"
                          />
                          <small
                            >Masukkan Nilai COD yg akan ditagih oleh si Penerima
                            Paket.</small
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card mt-2">
                    <div class="card-body">
                      <h6 class="text-secondary">
                        #EKSPEDISI & ESTIMASI BIAYA
                      </h6>
                      <div
                        v-if="showButtonCheckTariff"
                        class="text-center"
                        style="padding: 20px"
                      >
                        <argon-button
                          variant="outline"
                          :loading="isLoadingCheckTariff"
                          @click.prevent="submitCheckTariff"
                          >CEK TARIF</argon-button
                        >
                      </div>
                      <div v-if="showTariff">
                        <div
                          v-for="(tariff, i) in results_tariff"
                          :key="tariff"
                          class="mt-2"
                        >
                          <div
                            :class="getSelectedCourierRow(i)"
                            class="card card-options"
                            @click="courierSelectedTariff(i, tariff)"
                          >
                            <div class="card-body">
                              <table width="100%">
                                <tr>
                                  <td width="80%" class="text-left">
                                    <b
                                      >{{ tariff.courier_name }} ({{
                                        tariff.courier_type
                                      }})</b
                                    ><br />
                                    <span class="text-sm">
                                      <span v-if="tariff.is_cod == true">
                                        COD
                                      </span>
                                      <span v-else>Non COD</span>
                                      <br />
                                      Berat: {{ tariff.display_weight }}
                                      {{ tariff.unit_weight }}
                                      <br />
                                      Ongkir:
                                      <s
                                        ><b class="text-danger"
                                          >Rp. {{ tariff.display_tariff }}</b
                                        ></s
                                      >&nbsp;
                                      <b class="text-success"
                                        >Rp.
                                        {{
                                          tariff.calculate_cashback[
                                            "display_tariff_after_cashback"
                                          ]
                                        }}</b
                                      ><br />
                                      <span v-if="tariff.is_cod == true">
                                        Nilai Barang: Rp.
                                        {{ tariff.display_price }}<br />
                                        Biaya COD: Rp.
                                        {{ tariff.display_cod_fee }}
                                        <span
                                          v-if="tariff.is_insurance == true"
                                        >
                                          <br />Asuransi: Rp.
                                          {{ tariff.display_insurance_fee
                                          }}<br />
                                        </span>
                                        <span v-else>
                                          {{ tariff.label_cannot_insurance
                                          }}<br />
                                        </span>
                                        <b class="text-success"
                                          >Nilai COD: Rp.
                                          {{ tariff.display_total_tariff }}</b
                                        >
                                      </span>
                                    </span>
                                  </td>
                                  <td width="20%" class="text-end">
                                    <span v-html="tariff.html_img_logo"></span>
                                  </td>
                                </tr>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card mt-2">
                    <div class="card-body">
                      <h6 class="text-secondary">
                        #ESTIMASI NILAI
                        <span v-html="labelEarningOrBill"></span>
                      </h6>
                      <div style="padding: 20px">
                        <h3>{{ earningOrBill }}</h3>
                      </div>
                    </div>
                  </div>
                  <div class="mt-2 text-end">
                    <argon-button
                      v-if="showSubmitForm"
                      color="primary"
                      variant="gradient"
                      class="my-4 mb-2"
                      :loading="isLoading"
                      >Submit Data</argon-button
                    >
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <modal v-model:show="modals.classic" size="lg">
    <div class="p-2">
      <div class="row">
        <div class="col-md-6"><h5>Alamat Pengirim</h5></div>
        <div class="col-md-6 text-end text-sm">
          <el-tooltip
            class="box-item"
            effect="dark"
            content="Buat alamat baru"
            placement="top-start"
          >
            <a href="#" class="text-primary" @click.prevent="createNewAddress"
              ><i class="fas fa-edit text-primary"></i> Buat alamat baru</a
            >
          </el-tooltip>
          &nbsp;&nbsp;
          <el-tooltip
            class="box-item"
            effect="dark"
            content="Gunakan Dropship"
            placement="top-start"
          >
            <a
              href="#"
              class="text-primary"
              @click.prevent="showOfficialDropshipStore"
              ><i class="fas fa-star text-primary"></i> Official Dropship
              Store</a
            >
          </el-tooltip>
        </div>
      </div>
      <div class="row mt-4">
        <div
          v-for="pickup in defaultAddressPickup"
          :key="pickup.id"
          class="col-md-4"
        >
          <div
            class="box-link"
            @click.prevent="handleSelectedAddressPickup(pickup)"
          >
            <div>
              <h6>{{ pickup.name }}</h6>
              <p class="text-sm">
                {{ pickup.address }}<br /><small class="text-secondary">{{
                  pickup.area
                }}</small>
              </p>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <button
        type="button"
        class="btn btn-link"
        @click="modals.classic = false"
      >
        Close
      </button>
    </div>
  </modal>

  <modal v-model:show="modals.classic2" size="lg">
    <div class="p-2">
      <div class="row">
        <div class="col-md-6">
          <h5>Official Dropship Store</h5>
        </div>
        <div class="col-md-6 text-end text-sm">
          <el-tooltip
            class="box-item"
            effect="dark"
            content="Tampilkan alamat saya"
            placement="top-start"
          >
            <a href="#" class="text-primary" @click.prevent="showAddressPickup"
              ><i class="fas fa-home text-primary"></i> Alamat saya</a
            >
          </el-tooltip>
        </div>
      </div>
      <div class="row p-4">
        <div
          v-for="officialDropship in officialDropshipStore"
          :key="officialDropship.code"
          class="col-md-6 card"
        >
          <div class="card-body">
            <b>{{ officialDropship.name }}</b>
            <p>
              {{ officialDropship.address }}<br />
              {{ officialDropship.phone }}, {{ officialDropship.email }}
            </p>
            <argon-button
              color="primary"
              size="sm"
              variant="gradient"
              class="my-4 mb-2"
              @click="handleSelectedOfficialDropshipStore(officialDropship)"
              >Gunakan</argon-button
            >
          </div>
        </div>
      </div>

      <hr style="margin-bottom: -20px" />
      <button
        type="button"
        class="btn btn-link"
        style="margin-top: 35px"
        @click="modals.classic2 = false"
      >
        Close
      </button>
    </div>
  </modal>
  <modal v-model:show="modals.classic3" size="lg">
    <div class="p-2">
      <div class="row">
        <div class="col-md-6"><h5>Alamat Penerima</h5></div>
        <div class="col-md-6 text-end text-sm">
          <el-tooltip
            class="box-item"
            effect="dark"
            content="Buat alamat baru"
            placement="top-start"
          >
            <a href="#" class="text-primary" @click.prevent="createNewAddress"
              ><i class="fas fa-edit text-primary"></i> Buat alamat baru</a
            >
          </el-tooltip>
          &nbsp;&nbsp;
        </div>
      </div>
      <div class="row">
        <div class="col-md-2"></div>
        <div class="col-md-8">
          <el-input
            v-model="searchQuery"
            type="search"
            class="mb-3"
            clearable
            prefix-icon="el-icon-search"
            placeholder="Search records"
            aria-controls="datatables"
          >
          </el-input>
        </div>
        <div class="col-md-2"></div>
      </div>
      <div class="row mt-4">
        <!-- data tables here -->
        <el-table
          :data="queriedData"
          row-key="id"
          header-row-class-name="thead-light"
          @selection-change="selectionChange"
        >
          <el-table-column
            v-for="column in tableColumns"
            :key="column.label"
            v-bind="column"
          >
          </el-table-column>
          <el-table-column min-width="100px" align="left" label="Action">
            <template #default="props">
              <argon-button
                color="primary"
                variant="outline"
                size="sm"
                @click.prevent="handleSelectedAddressNoPickup(props.row)"
                >Gunakan</argon-button
              >
            </template>
          </el-table-column>
        </el-table>

        <div
          class="mt-4 col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
        >
          <div class="">
            <p class="card-category">
              Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
            </p>
          </div>
          <base-pagination
            v-model="pagination.currentPage"
            class="pagination-no-border"
            :per-page="pagination.perPage"
            :total="total"
          >
          </base-pagination>
        </div>
      </div>
      <hr />
      <button
        type="button"
        class="btn btn-link"
        @click="modals.classic3 = false"
      >
        Close
      </button>
    </div>
  </modal>
  <modal v-model:show="modals.classic4" size="lg">
    <div class="p-2">
      <h5>Katalog Produk</h5>
      <ChooseProductCatalogs
        v-if="showProductCatalogs"
        :official_dropship_store_id="form.official_dropship_store_id"
        :product_type="form.product_type"
        @selected-product-catalog-callback="
          handleSelectedProductCatalogCallback
        "
      />
      <hr />
      <button
        type="button"
        class="btn btn-link"
        @click="modals.classic4 = false"
      >
        Close
      </button>
    </div>
  </modal>
</template>

<script>
import Auth from "@/components/auth/Auth";
import axios from "axios";
import Swal from "sweetalert2";
import Modal from "@/components/Modal";
import ArgonButton from "@/components/ArgonButton.vue";
import ArgonBadge from "@/components/ArgonBadge.vue";
import ArgonCheckbox from "@/components/ArgonCheckbox.vue";
import ArgonAlert from "@/components/ArgonAlert.vue";
import {
  ElTable,
  ElTableColumn,
  ElSelect,
  ElOption,
  ElInput,
  ElLoading,
  ElTooltip,
  ElSkeleton,
} from "element-plus";
import BasePagination from "@/components/BasePagination";
import AutocompleteZonecode from "@/components/AutocompleteZonecode.vue";
import ChooseProductCatalogs from "@/components/ChooseProductCatalogs.vue";

export default {
  name: "CreateSendPackages",
  components: {
    BasePagination,
    Auth,
    ArgonButton,
    ArgonBadge,
    ArgonCheckbox,
    ArgonAlert,
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
    [ElTable.name]: ElTable,
    [ElInput.name]: ElInput,
    [ElTableColumn.name]: ElTableColumn,
    ElTooltip,
    ElSkeleton,
    Modal,
    AutocompleteZonecode,
    ChooseProductCatalogs,
  },
  data() {
    return {
      modals: {
        classic: false,
        classic2: false,
        classic3: false,
        classic4: false,
      },
      urlstore: "/api/v1/send_packages/store",
      urlsearch: "/api/v1/search_zonecodes",
      urltariff: "/api/v1/courier_tariff",
      urlDefaultAddressPickup: "/api/v1/address_lists/default_address_pickup",
      urlAllAddressExtendPickup: "/api/v1/address_lists/read_extend_pickup",
      urlproductcategories: "/api/v1/product_categories/select",

      dataTable: [],
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      searchQuery: "",
      propsToSearch: ["name", "address"],
      tableColumns: [
        {
          prop: "name",
          label: "Nama",
          minWidth: 100,
          sortable: true,
        },
        {
          prop: "address",
          label: "Alamat",
          minWidth: 100,
          sortable: true,
        },
      ],
      datas: [],
      tableData: [],
      fuseSearch: null,
      searchedData: [],
      isLoading: false,
      loadingTable: false,

      selects: {
        product_categories: [],
        subdistricts: [],
        is_pickup_required: [
          {
            value: 1,
            name: "Pick Up",
          },
          {
            value: 0,
            name: "Drop Off",
          },
        ],
        is_cod: [
          {
            value: 1,
            name: "COD (Cash on Delivery)",
          },
          {
            value: 0,
            name: "Non COD",
          },
        ],
        address_types: [
          {
            value: "home",
            name: "Rumah",
          },
          {
            value: "office",
            name: "Kantor",
          },
        ],
      },

      skeletonLoadingOriginDetail: false,
      showOriginDetail: false,

      selectedOfficialDropshipStore: [],
      defaultAddressPickup: [],
      defaultAddressNoPickup: [],
      officialDropshipStore: [],
      showAutocompleteDestination: true,
      searchAddressNoPickup: "",
      filteredAddressNoPickup: [],

      officialProductCatalogLists: [],
      showProductCatalogs: false,

      selectedProductCatalog: [],

      showAlertChoosePickup: false,
      showAlertChooseDropoff: false,

      showButtonCheckTariff: true,
      showTariff: false,
      results_tariff: [],
      total_results_tariff: "",
      htmlDisplayTariff: "",

      isLoadingCheckTariff: false,

      showSubmitForm: false,

      labelEarningOrBill: "",
      earningOrBill: "Rp. 0,-",

      formAddProduct: true,
      tableCartProduct: false,
      showInputCustomCOD: false,

      showFormAddressDestination: true,
      showEditAddressForm: false,
      disabledFormDestination: false,

      showCheckedAddToProductCatalog: false,

      form: {
        official_dropship_store_id: "",
        origin_address_list_id: "",
        origin_ro_subdistrict_id: "",
        origin_id: "",
        origin: "",

        destination_address_list_id: "",
        destination_ro_subdistrict_id: "",
        destination_district_id: "",
        destination_subdistrict_id: "",
        destination_id: "",
        destination: "",
        destination_name: "",
        destination_phone: "",
        destination_address: "",
        destination_postalcode: "",
        destination_address_type: "",
        checkedAddToAddressList: false,

        product_type: "",
        product_catalog_id: "",
        product_name: "",
        product_category_id: "",
        product_price: "",
        product_qty: 1,
        product_weight: "",
        product_length: "",
        product_width: "",
        product_height: "",
        product_description: "",
        checkedAddToProductCatalog: false,

        is_pickup_required: "",
        is_cod: "",
        is_dangerous_good: false,
        checkedIsInsuranceRequired: false,

        input_custom_cod: 0,

        cartProductItems: [],

        edit: false,
        bulk_list_package_id: "",
        send_package_id: "",
      },
      origin: {
        labelName: "",
        labelAddress: "",
        labelAddressType: "",
        labelArea: "",
        labelCellphone: "",
      },
      courierTariffSelected: [],

      is_admin: 0,
    };
  },
  computed: {
    getSelectedCourierRow() {
      return (i) => {
        return "option-courier-tariff-" + i;
      };
    },
    pagedData() {
      return this.tableData.slice(this.from, this.to);
    },
    /***
     * Searches through table data and returns a paginated array.
     * Note that this should not be used for table with a lot of data as it might be slow!
     * Do the search and the pagination on the server and display the data retrieved from server instead.
     * @returns {computed.pagedData}
     */
    queriedData() {
      if (!this.searchQuery) {
        return this.pagedData;
      }
      let result = this.tableData.filter((row) => {
        let isIncluded = false;
        for (let key of this.propsToSearch) {
          let rowValue = row[key].toString();
          if (rowValue.includes && rowValue.includes(this.searchQuery)) {
            isIncluded = true;
          }
        }
        return isIncluded;
      });

      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    },
  },
  created() {
    this.checkParamRoute();
    this.getAllAddressExtendPickup();
    this.getProductCategories();
  },
  methods: {
    loadingService() {
      const loading = ElLoading.service({
        lock: true,
        text: "Loading",
        background: "rgba(0, 0, 0, 0.1)",
      });

      return loading;
    },
    checkParamRoute() {
      let mode = this.$route.params.mode;
      if (mode == "official") {
        let selected = this.$route.params.selected;
        this.selectedOfficialDropshipStore = JSON.parse(selected);
        this.setupOriginPickupOfficialDropshipStore(
          this.selectedOfficialDropshipStore
        );
        this.showCartProductItems();
      } else if (mode == "edit") {
        // get bulk_list_package_id
        let bulk_list_package_id = this.$route.params.bulk_list_package_id;
        this.form.bulk_list_package_id = bulk_list_package_id;

        // get send_package_id
        let send_package_id = this.$route.params.send_package_id;
        this.form.send_package_id = send_package_id;
        //console.log('error: '+this.form.send_package_id)

        // get destination_address
        let destination_address = this.$route.params.destination_address;
        if(destination_address != '') {
          destination_address = JSON.parse(destination_address);
        }

        let paramData = this.$route.params.data;
        let is_admin = this.$route.params.is_admin;

        this.is_admin = is_admin;

        if (bulk_list_package_id != null) {
          //console.log("edit bulk_list_package_id");
          //console.log(bulk_list_package_id);
          if (paramData) {
            let data = JSON.parse(paramData);
            this.getDataEditForm(bulk_list_package_id,"bulk_list_package_id",data,destination_address);
          }
        } else if (send_package_id != null) {
          console.log("edit send_package_id");
          console.log(send_package_id);
        }
      }
    },
    async getDataEditForm(id, type, data, destination_address) {
      try {
        this.loadingService();
        if (type == "bulk_list_package_id") {
          this.form.edit = true;
          this.form.bulk_list_package_id = id;
          if (data) {
            // official dropship_store
            if (data.official_dropship_store) {
              this.form.product_type = "official";
              //console.log(data.official_dropship_store);

              this.selectedOfficialDropshipStore = data.official_dropship_store;
              this.setupOriginPickupOfficialDropshipStore(
                this.selectedOfficialDropshipStore
              );
            }

            // destination
            if(destination_address == '') {
              this.form.destination_name = data.to_name;
              this.form.destination_phone = data.to_phone_number;
              this.form.destination_address = data.to_address_1;
              this.form.destination_postalcode = data.postalcode;
              this.form.destination_address_type = "home";
            }else{
              this.handleSelectedAddressNoPickup(destination_address);
            }

            // product
            if (data.cart_product_items) {
              console.log(data.cart_product_items);
              this.form.cartProductItems = data.cart_product_items;
              // console.log(this.cartProductItems.length)
              this.showCartProductItems();
            }

            // pickup method
            this.form.is_pickup_required = 1;
            this.showAlertChoosePickup = true;
            this.showAlertChooseDropoff = false;

            this.form.is_cod = 1;
            this.labelEarningOrBill =
              '<span class="text-success">PENCAIRAN</span>';
            this.showInputCustomCOD = true;
            this.calculateInputCustomCOD();

            // custom input cod
            this.form.input_custom_cod = data.cash_on_delivery;
          }
        }

        this.loadingService().close();
      } catch (error) {
        console.log(error);
        this.loadingService().close();
      }
    },
    async getAllAddressExtendPickup() {
      this.skeletonLoadingOriginDetail = true;
      this.showOriginDetail = false;
      try {
        const response = await axios.get(this.urlAllAddressExtendPickup, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        //console.log(response.data)
        // get data pickup
        if (response.data.pickup.length > 0) {
          this.defaultAddressPickup = response.data.pickup;
        } else {
          if (response.data.nopickup.length > 0) {
            this.defaultAddressPickup = response.data.nopickup;
          }
        }

        // get data nopickup
        if (response.data.nopickup.length > 0) {
          this.defaultAddressNoPickup = response.data.nopickup;
          this.datas = this.defaultAddressNoPickup;
          this.tableData = this.datas;
        }

        this.checkSelectedOrigin();

        this.skeletonLoadingOriginDetail = false;
        this.showOriginDetail = true;
      } catch (error) {
        console.log(error);
        this.skeletonLoadingOriginDetail = false;
        this.showOriginDetail = false;
        this.errorRedirect500(error);
      }
    },
    async getProductCategories() {
      try {
        const response = await axios.get(this.urlproductcategories, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        this.selects.product_categories = response.data;
        //console.log(this.datas);
      } catch (error) {
        console.log(error);
        this.errorRedirect500(error);
      }
    },
    async getOfficialDropshipStore() {
      this.loadingService();
      try {
        const response = await axios.get(
          "api/v1/official_dropship_store/showlistaddress",
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        );
        this.officialDropshipStore = response.data.datatables;
        this.loadingService().close();
      } catch (error) {
        console.log(error);
        this.loadingService().close();
        this.errorRedirect500(error);
      }
    },
    setupOriginPickupOfficialDropshipStore(originDropshipAddress) {
      //alert(JSON.stringify(originDropshipAddress));
      this.origin.labelName = originDropshipAddress.name;
      this.origin.labelAddress = originDropshipAddress.address;
      this.origin.labelAddressType = originDropshipAddress.address_type;
      this.origin.labelArea = originDropshipAddress.area;

      this.form.origin_address_list_id = "";
      this.form.official_dropship_store_id = originDropshipAddress.id;
      this.form.origin_ro_subdistrict_id =
        originDropshipAddress.ro_subdistrict_id;
      this.form.origin_id = originDropshipAddress.ro_subdistrict_id;
      this.form.origin = originDropshipAddress.area;
    },
    setupOriginPickupOwnAddress(originAddress) {
      this.origin.labelName = originAddress.name;
      this.origin.labelAddress = originAddress.address;
      this.origin.labelAddressType = originAddress.address_type;
      this.origin.labelArea = originAddress.area;

      this.form.official_dropship_store_id = "";
      this.form.origin_id = originAddress.ro_subdistrict_id;
      this.form.origin_ro_subdistrict_id = originAddress.ro_subdistrict_id;
      this.form.origin_address_list_id = originAddress.id;
      this.form.origin = originAddress.area;
    },
    checkSelectedOrigin() {
      if (this.selectedOfficialDropshipStore != "") {
        this.setupOriginPickupOfficialDropshipStore(
          this.selectedOfficialDropshipStore
        );
      } else {
        if (this.defaultAddressPickup != "") {
          this.setupOriginPickupOwnAddress(this.defaultAddressPickup[0]);
        } else {
          if (this.defaultAddressNoPickup != "") {
            this.defaultAddressPickup = this.defaultAddressNoPickup;
            this.setupOriginPickupOwnAddress(this.defaultAddressPickup[0]);
          }
        }
      }
    },
    createNewAddress() {
      this.$router.push({ path: "/orders/addresslists" });
    },
    handleSelectedAddressPickup(pickup) {
      this.modals.classic = false;
      this.setupOriginPickupOwnAddress(pickup);

      this.clearProductForm();
      this.handleClearResultTariff();
      this.clearCartProductItems();
    },
    handleSelectedOfficialDropshipStore(officialDropship) {
      this.modals.classic2 = false;
      this.selectedOfficialDropshipStore = officialDropship;
      this.setupOriginPickupOfficialDropshipStore(officialDropship);

      this.clearProductForm();
      this.handleClearResultTariff();
      this.clearCartProductItems();

      // if official dropship selected => hide form & show cart product items
      this.showCartProductItems();
    },
    showOfficialDropshipStore() {
      this.modals.classic = false;
      this.modals.classic2 = true;
      if (this.officialDropshipStore == "") {
        this.getOfficialDropshipStore();
      }
    },
    showAddressPickup() {
      this.modals.classic2 = false;
      this.modals.classic = true;
    },
    async getSubDistrictSelect(district_id) {
      this.loadingService();
      try {
        const response = await axios.get(
          "api/v1/subdistrict/select/" + district_id,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        );
        this.selects.subdistricts = response.data;
        this.loadingService().close();
      } catch (error) {
        console.log(error);
        this.loadingService().close();
        this.errorRedirect500(error);
      }
    },
    async getPostalcode(area) {
      this.loadingService();
      try {
        const response = await axios.get(
          "api/v1/postalcode?itemsPerPage=10&search=" + area,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        );
        //console.log(response.data.data.data[0].postalcode);
        this.form.destination_postalcode = response.data.data.data[0].postalcode;
        this.loadingService().close();
      } catch (error) {
        console.log(error);
        this.loadingService().close();
        this.errorRedirect500(error);
      }
    },
    handleSelectSubDistrict() {
      this.getSubDistrictSelect(this.form.destination_district_id);
    },
    handlePostalcode() {
      const fullArea = this.form.destination; // Output: "Cengkareng, Jakarta Barat, DKI Jakarta"
      const areaParts = fullArea.split(',');
      if (areaParts.length >= 2) {
        const desiredArea = `${areaParts[0].trim()}, ${areaParts[1].trim()}`; // Output: "Cengkareng, Jakarta Barat"
        this.getPostalcode(desiredArea);
      }      
    },
    handleAutocompleteCallback(values) {
      if (values.district_id !== undefined) {
        this.form.destination_ro_subdistrict_id = values.id;
        this.form.destination_id = values.id;
        this.form.destination_district_id = values.district_id;
        this.form.destination = values.area;
        this.handleSelectSubDistrict();
        this.handlePostalcode();
      }

      if(values.postalcodes[0] !== undefined) {
        if(values.postalcodes[0].postalcode !== undefined) {
          this.form.destination_postalcode = values.postalcodes[0].postalcode;
        }
      }
    },
    handleAutocompleteLoadingCallback(values) {
      if (values == true) {
        /*
        this.form.destination_address_list_id = "";
        this.form.destination_ro_subdistrict_id = "";
        this.form.destination_id = "";
        this.form.destination_district_id = "";
        this.form.destination = "";
        this.form.destination_name = "";
        this.form.destination_phone = "";
        this.form.destination_address = "";
        */
        this.clearResultTariff();
      }
    },
    handleAddToAddressList(values) {
      this.form.checkedAddToAddressList = values.target.checked;
      if (values.target.checked == true) {
        this.disabledFormDestination = false;
      } else {
        this.disabledFormDestination = true;
      }
    },
    handleAddToProductCatalog(values) {
      this.form.checkedAddToProductCatalog = values.target.checked;
    },
    handleShowAddressListsNoPickup() {
      this.modals.classic3 = true;
      this.filteredAddressNoPickup = this.defaultAddressNoPickup;
      this.datas = this.defaultAddressNoPickup;
      this.tableData = this.datas;
    },
    handleSelectedAddressNoPickup(nopickup) {
      this.modals.classic3 = false;
      // get subdistrict
      this.getSubDistrictSelect(nopickup.district_id);

      this.form.destination_address_list_id = nopickup.id;
      this.form.destination_ro_subdistrict_id = nopickup.ro_subdistrict_id;
      this.form.destination_id = nopickup.ro_subdistrict_id;
      this.form.destination_district_id = nopickup.district_id;
      this.form.destination_subdistrict_id = nopickup.subdistrict_id;

      this.form.destination_name = nopickup.name;
      this.form.destination_address = nopickup.address;
      this.form.destination_phone = nopickup.phone;
      this.form.destination_postalcode = nopickup.postalcode;
      this.form.destination_address_type = nopickup.address_type;

      this.showEditAddressForm = true;
      this.disabledFormDestination = true;
      this.form.checkedAddToAddressList = false;

      this.showAutocompleteDestination = false;
      this.handleClearResultTariff();

      setTimeout(() => {
        this.form.destination = nopickup.area;
        this.showAutocompleteDestination = true;
        this.destination_ro_subdistrict_id = nopickup.ro_subdistrict_id;
      }, 100);
    },
    handleShowProductCatalog() {
      this.modals.classic4 = true;

      if (this.form.official_dropship_store_id != "") {
        // show official product
        this.form.product_type = "official";
      } else {
        // show own product catalog
        this.form.official_dropship_store_id = 0;
        this.form.product_type = "user";
      }
      this.showProductCatalog();
    },
    showProductCatalog() {
      this.showProductCatalogs = false;
      setTimeout(() => {
        this.showProductCatalogs = true;
      }, 500);
    },
    handleSelectedProductCatalogCallback(values) {
      // add key qty
      values.qty = 1;

      //console.log(data);

      this.selectedProductCatalog = values;
      // check exist cartproductitems and validate
      if (this.form.cartProductItems.length > 0) {
        const itemExist = this.form.cartProductItems.some(
          (item) => item.id === values.id
        );
        if (itemExist == false) {
          this.form.cartProductItems.push(values);
          this.showCartProductItems();
        }
      } else {
        this.form.cartProductItems.push(values);
        this.showCartProductItems();
      }

      this.calculateInputCustomCOD();

      this.modals.classic4 = false;
    },
    showCartProductItems() {
      this.formAddProduct = false;
      this.tableCartProduct = true;
    },
    showFormAddProduct() {
      this.formAddProduct = true;
      this.tableCartProduct = false;
    },
    clearCartProductItems() {
      this.form.cartProductItems = [];
      this.showFormAddProduct();

      this.calculateInputCustomCOD();
    },
    clearProductForm() {
      this.selectedProductCatalog = "";
      this.form.product_catalog_id = "";
      this.form.product_name = "";
      this.form.product_category_id = "";
      this.form.product_price = "";
      this.form.product_qty = "";
      this.form.product_weight = "";
      this.form.product_length = "";
      this.form.product_width = "";
      this.form.product_height = "";
      this.form.product_description = "";
    },
    handlePickupRequired() {
      if (this.form.is_pickup_required == true) {
        this.showAlertChoosePickup = true;
        this.showAlertChooseDropoff = false;
      } else {
        this.showAlertChoosePickup = false;
        this.showAlertChooseDropoff = true;
      }
    },
    handleUpdateQtyCartProduct(type, index, qty) {
      if (type == "up") {
        this.form.cartProductItems[index].qty = qty + 1;
      } else {
        if (qty > 1) {
          this.form.cartProductItems[index].qty = qty - 1;
        }
      }
      this.handleClearResultTariff();
      this.calculateInputCustomCOD();
    },
    handleDeleteCartProduct(index) {
      this.form.cartProductItems.splice(index, 1);
      if (this.form.cartProductItems.length == 0) {
        if (this.form.official_dropship_store_id == 0) {
          this.showFormAddProduct();
        }
      }
      this.handleClearResultTariff();
      this.calculateInputCustomCOD();
    },
    handleClearResultTariff() {
      this.clearResultTariff();
    },
    handleSelectCOD() {
      this.clearResultTariff();
      if (this.form.is_cod == 1) {
        this.labelEarningOrBill = '<span class="text-success">PENCAIRAN</span>';
        this.showInputCustomCOD = true;
        this.calculateInputCustomCOD();
      } else {
        this.labelEarningOrBill = '<span class="text-danger">TAGIHAN</span>';
        this.showInputCustomCOD = false;
        this.form.input_custom_cod = "";
        this.clearCalculateInputCustomCOD();
      }
    },
    calculateInputCustomCOD() {
      if (this.form.is_cod == 1) {
        let totalPrice = 0;
        this.form.input_custom_cod = 0;
        if (this.form.cartProductItems.length > 0) {
          console.log(this.form.cartProductItems);
          for (let x in this.form.cartProductItems) {
            totalPrice =
              totalPrice +
              this.form.cartProductItems[x].price *
                this.form.cartProductItems[x].qty;
          }
          this.form.input_custom_cod = totalPrice * 2;
        } else {
          this.form.input_custom_cod = this.form.product_price;
        }
      }
    },
    clearCalculateInputCustomCOD() {
      this.form.input_custom_cod = 0;
    },
    handleChangeInputCustomCOD() {
      this.clearResultTariff();
    },
    handleIsInsuranceRequired(values) {
      this.form.checkedIsInsuranceRequired = values.target.checked;
      this.clearResultTariff();
    },
    async submitCheckTariff() {
      this.isLoadingCheckTariff = true;
      this.showTariff = false;
      let formData = {
        official_dropship_store_id: this.form.official_dropship_store_id,
        origin_id: this.form.origin_id,
        origin: this.form.origin,
        destination_id: this.form.destination_id,
        destination: this.form.destination,
        weight: this.form.product_weight,
        dim_length: this.form.product_length,
        dim_width: this.form.product_width,
        dim_height: this.form.product_height,
        opsiCOD: this.form.is_cod,
        opsiDimensi: 1,
        opsiAsuransi: this.form.checkedIsInsuranceRequired,
        price: this.form.product_price,
        cart_product_items: this.form.cartProductItems,
        input_custom_cod: this.form.input_custom_cod,
      };
      //console.log(formData);

      try {
        const response = await axios({
          method: "post",
          url: this.urltariff,
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          data: formData,
        });
        this.isLoadingCheckTariff = false;
        if (response.data.status == "success") {
          // set results_tariff
          this.results_tariff = response.data.result_tariff;
          this.total_results_tariff = response.data.total_results_tariff;
          this.showTariff = true;
          this.htmlDisplayTariff = response.data.html_display_tariff;
          this.showButtonCheckTariff = false;

          // check if total_results is only 1
          if (this.total_results_tariff == 1) {
            setTimeout(() => {
              document.getElementsByClassName("card-options")[0].style.border =
                "2px solid #5e72e4";

              this.form.courierTariffSelected = this.results_tariff[0];
              this.showSubmitForm = true;
              this.calculatedEarningOrBill(this.form.courierTariffSelected);
            }, 250);
          }
        } else {
          this.showTariff = false;
          Swal.fire(
            "Warning!",
            "Tidak dapat menampilkan Tarif, data belum lengkap.",
            "warning"
          );
        }
      } catch (error) {
        this.isLoadingCheckTariff = false;
        this.showTariff = false;
        this.showButtonCheckTariff = true;
        console.log(error);
        Swal.fire({
          title: "Warning",
          html: "Tidak dapat menampilkan Tarif, data belum lengkap.<br /><br /><small><i>"+error.response.data.message+"</i></small>"
          /*"Warning!",
          "Tidak dapat menampilkan Tarif, data belum lengkap. "+error.response.data.message+" (Hubungi administrator untuk memperbaharui tarif)",
          "warning"*/
        });        
        this.errorRedirect500(error);
      }
    },
    clearResultTariff() {
      this.showButtonCheckTariff = true;
      this.showTariff = false;
      this.showSubmitForm = false;
      this.earningOrBill = "Rp. 0,-";
    },
    courierSelectedTariff(i, row_tariff) {
      for (let ii = 0; ii < this.total_results_tariff; ii++) {
        document.getElementsByClassName("card-options")[ii].style.border =
          "2px solid white";
      }

      setTimeout(() => {
        document.getElementsByClassName("card-options")[i].style.border =
          "2px solid #5e72e4";
        this.showSubmitForm = true;
        this.calculatedEarningOrBill(row_tariff);
      }, 250);

      this.form.courierTariffSelected = row_tariff;
    },
    calculatedEarningOrBill(row_tariff) {
      this.earningOrBill = "Rp. " + row_tariff.display_earning_or_bill;
    },
    async onSubmit() {
      try {
        this.loadingService();
        this.isLoading = true;
        const response = await axios({
          method: "post",
          url: this.urlstore,
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          data: this.form,
        });
        this.isLoading = false;
        this.loadingService().close();
        if (response.data.status == "success") {
          console.log(response.data.message);
          // swal with autoclose timer
          //Swal.fire("Success!", response.data.message, "success");
          Swal.fire({
            title: "Success!",
            html: response.data.message,
            icon: "success",
            timer: 1500,
            showConfirmButton: false,
          });
          // redirect to history packages
          if(this.form.bulk_list_package_id == '') {
            this.$router.push({ path: "/orders/historypackages" });
          }else{
            if(this.is_admin == 1) {
              this.$router.push({ path: "/pages/admin/bulk_packages" });
            }else{
              this.$router.push({ path: "/orders/bulkpackages" });
            }
          }
        } else {
          console.log(response.data.message);
          Swal.fire("Warning!", response.data.message, "warning");
        }
      } catch (error) {
        this.isLoading = false;
        this.loadingService().close();
        console.log(error);
        this.errorRedirect500(error);
      }
    },
    handleRedirectImportCSV() {
      this.$router.push({ path: "/orders/sendpackages/import" });
    },
    errorRedirect500(error) {
      console.log(error);
      if (error.response.status == 500) {
        setTimeout(() => {
          this.$router.push({ path: "/authentication/error/error500" });
        }, 2000);
      }
    },
  },
};
</script>

<style scoped>
.box-link {
  height: 250px;
  border: 2px dashed #cccccc;
  border-radius: 5px;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}
.box-link:hover {
  cursor: pointer;
  border: 2px dashed #6c6be4;
}
.box-link-left {
  border: 1px dashed #cccccc;
  border-radius: 5px;
  padding: 20px 20px 0px 20px;
  text-align: left;
  margin-bottom: 10px;
}
.box-link-left:hover {
  cursor: pointer;
  border: 1px dashed #6c6be4;
}
.card-options {
  border: 2px solid #ffffff;
}
.card-options:hover {
  cursor: pointer;
  /*border: 2px solid #5e72e4;*/
  background-color: #eeeeee;
}
.input-number-updown {
  display: inline-block;
  border: 1px solid #eeeeee;
}
.input-number-updown ul {
  list-style-type: none;
  margin: 0px;
  padding: 0px;
}
.input-number-updown ul li {
  display: inline-block;
  padding: 5px 15px 5px 15px;
}
.input-number-updown ul li.numberdown {
  display: inline-block;
}
.input-number-updown ul li.numberdown:hover,
.input-number-updown ul li.numberup:hover {
  cursor: pointer;
}
.input-number-updown ul li.numbervalue {
  padding: 5px 30px 5px 30px;
}
.cart-row-product {
  padding: 10px;
  border-radius: 5px;
}
/*
.cart-row-product:hover {
  background-color: #f6f9fc;
}
*/
</style>
