<template>
  <div>
    <div>
      <div class="row">
        <div class="col-md-2"></div>
        <div class="col-md-8">
          <el-input
            v-model="searchQuery"
            type="search"
            class="mb-3"
            clearable
            prefix-icon="el-icon-search"
            placeholder="Search records"
            aria-controls="datatables"
          >
          </el-input>
        </div>
        <div class="col-md-2"></div>
      </div>
    </div>
    <div class="table-responsive">
      <el-table
        :data="queriedData"
        row-key="id"
        border
        style="width: 100%"
        header-row-class-name="thead-light"
        @selection-change="selectionChange"
      >
        <el-table-column
          v-for="column in tableColumns"
          :key="column.label"
          v-bind="column"
        >
        </el-table-column>
        <el-table-column align="center" width="120" label="Gambar">
          <template #default="props">
            <span v-if="props.row.url_main_product_image != ''">
              <el-image
                style="width: 100px; height: 100px"
                :src="props.row.url_main_product_image"
                :fit="cover"
                lazy
              />
            </span>
            <span v-else>
              <small>no img</small>
            </span>
          </template>
        </el-table-column>
        <el-table-column align="left" label="Deskripsi">
          <template #default="props">
            <p class="text-secondary" style="font-size: 12px">
              <b>Nama Produk:</b> {{ props.row.product_name }}<br />
              <b>Harga:</b> {{ props.row.display_order_price }}<br />
              <b>Berat/Dimensi:</b> {{ props.row.weight }} gram / P.{{
                props.row.length
              }}
              cm L.{{ props.row.width }} cm T.{{ props.row.height }} cm<br />
              <b>Dropship Store:</b>
              {{ props.row.official_dropship_store_name }},
              {{ props.row.district_name }},
              {{ props.row.province_name }}<br />
              <b>Deskripsi:</b> {{ props.row.description }}
            </p>
            <div class="mt-4 mb-2">
              <argon-button
                variant="outline"
                size="sm"
                @click.prevent="handleSelectedProductCatalog(props.row)"
                >Gunakan</argon-button
              >
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <br />
    <div
      class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
    >
      <div class="">
        <p class="card-category">
          Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
        </p>
      </div>
      <base-pagination
        v-model="pagination.currentPage"
        class="pagination-no-border"
        :per-page="pagination.perPage"
        :total="total"
      >
      </base-pagination>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import ArgonButton from "@/components/ArgonButton.vue";
import {
  ElTable,
  ElTableColumn,
  ElSelect,
  ElOption,
  ElInput,
  ElLoading,
  ElImage,
} from "element-plus";
import BasePagination from "@/components/BasePagination";

export default {
  name: "ChooseProductCatalogs",
  components: {
    BasePagination,
    ArgonButton,
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
    [ElTable.name]: ElTable,
    [ElInput.name]: ElInput,
    [ElTableColumn.name]: ElTableColumn,
    ElImage,
  },
  props: {
    product_type: { required: true, type: String },
    official_dropship_store_id: { required: true, type: Number },
  },
  data() {
    return {
      urlread: "/api/v1/product_catalog/choose",
      dataTable: [],
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10],
        total: 0,
      },
      searchQuery: "",
      propsToSearch: ["product_name","official_dropship_store_name"],
      tableColumns: [],
      datas: [],
      tableData: [],
      fuseSearch: null,
      searchedData: [],
      isLoading: false,
      loadingTable: true,
    };
  },
  computed: {
    pagedData() {
      return this.tableData.slice(this.from, this.to);
    },
    /***
     * Searches through table data and returns a paginated array.
     * Note that this should not be used for table with a lot of data as it might be slow!
     * Do the search and the pagination on the server and display the data retrieved from server instead.
     * @returns {computed.pagedData}
     */
     queriedData() {
      if (!this.searchQuery) {
        return this.pagedData;
      }
      return this.searchAction.slice(this.from, this.to);
    },
    searchAction() {
      let result = this.tableData.filter((row) => {
        let isIncluded = false;
        for (let key of this.propsToSearch) {
          if (row[key] !== null) {
            let rowValue = row[key].toString().toLowerCase();
            if (
              rowValue.includes &&
              rowValue.includes(this.searchQuery.toLowerCase())
            ) {
              isIncluded = true;
            }
          }
        }
        return isIncluded;
      });

      return result;
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchAction.length > 0
        ? this.searchAction.length
        : this.tableData.length;
    },
  },
  created() {
    this.getTables();
  },
  methods: {
    loadingService() {
      const loading = ElLoading.service({
        lock: true,
        text: "Loading",
        background: "rgba(0, 0, 0, 0.1)",
      });

      return loading;
    },
    async getTables() {
      try {
        this.loadingService();
        const response = await axios.get(
          this.urlread +
            "/" +
            this.product_type +
            "/" +
            this.official_dropship_store_id,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        );
        //console.log(response);
        this.datas = response.data.datatables;
        this.tableData = this.datas;
        //console.log(this.datas);
        this.loadingService().close();
      } catch (error) {
        console.log(error);
        this.loadingService().close();
        this.errorRedirect500(error);
      }
    },
    handleSelectedProductCatalog(row) {
      this.$emit("selected-product-catalog-callback", row);
    },
    errorRedirect500(error) {
      if (error.response.status == 500) {
        setTimeout(() => {
          this.$router.push({ path: "/authentication/error/error500" });
        }, 2000);
      }
    },
  },
};
</script>
